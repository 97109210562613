@use '@angular/material' as mat;
@import 'swiper/css/bundle';

@import 'colors';
@import 'styles/button';
@import 'styles/checkbox';
@import 'styles/form-field';
@import 'styles/input';
@import 'styles/option';
@import 'styles/list-item';
@import 'styles/chips';
@import 'styles/videoplayer';
@import 'styles/dialog';
@import 'styles/stepper';
@import 'styles/toggle';
@import 'styles/select';
@import 'styles/divider';
@import 'styles/inner-html';
@import 'styles/rich-text';
@import 'styles/menu';
@import 'styles/chat';
@import 'styles/image-cropper';
@import 'styles/spinner';

@font-face {
  font-family: 'Orkney';
  src: url('assets/fonts/orkney-bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Orkney';
  src: url('assets/fonts/orkney-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bn-navbar-width: 100%;
  --bn-navbar-width-md: 5.5rem;
  --bn-navbar-width-xl: 22.5rem;
}

* {
  font-variant-ligatures: none;
  font-family: 'Orkney', 'Helvetica Neue', sans-serif;
}

html,
body {
  height: 100%;

  &:has(.chat) {
    @apply h-auto min-h-full scrollbar-corner-rounded overflow-hidden;
  }
}
body {
  margin: 0;
  font-family: 'Orkney', 'Helvetica Neue', sans-serif;
}

$bn-brand: (
  50: #e5f0ff,
  100: #bfd9ff,
  200: #94c0ff,
  300: #69a6fe,
  400: #4893fe,
  500: $primary-color,
  600: #2478fe,
  700: #1e6dfe,
  800: #1863fe,
  900: #0f50fd,
  A100: #ffffff,
  A200: #f6f8ff,
  A400: #c3d1ff,
  A700: #a9bdff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$bn-orange: (
  50: #fef9e8,
  100: #fbf0c5,
  200: #f9e69e,
  300: #f7dc77,
  400: #f5d559,
  500: $secondary-color,
  600: #f1c836,
  700: #efc12e,
  800: #edba27,
  900: #eaae1a,
  A100: #ffffff,
  A200: #fff8ea,
  A400: #ffe9b7,
  A700: #ffe19d,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$bn-red: (
  50: #f6e0e4,
  100: #e7b3bc,
  200: #d88090,
  300: #c84d63,
  400: #bc2641,
  500: $error-color,
  600: #a9001c,
  700: #a00018,
  800: #970013,
  900: #87000b,
  A100: #ffb3b6,
  A200: #ff8085,
  A400: #ff4d53,
  A700: #ff343b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

$bn-primary: mat.m2-define-palette($bn-brand);
$bn-accent: mat.m2-define-palette($bn-orange);
$bn-warn: mat.m2-define-palette($bn-red);

$bn-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $bn-primary,
      accent: $bn-accent,
      warn: $bn-warn,
    ),
    typography:
      mat.m2-define-typography-config(
        $font-family: 'Orkney',
      ),
    density: 0,
  )
);

@include mat.core-theme($bn-theme);
@include mat.all-component-themes($bn-theme);
.mat-sidenav-content,
md-sidenav {
  display: flex;
  overflow: visible;
}
