vg-overlay-play .vg-overlay-play {
  transition: all 0.5s;
  cursor: pointer;
  position: absolute;
  display: block;
  color: white;
  width: 100%;
  height: 100%;
  font-size: 80px;
  filter: alpha(opacity=100) !important;
  opacity: 1 !important;
}

vg-overlay-play {
  .vg-overlay-play {
    .overlay-play-container {
      &.vg-icon-play_arrow {
        font-size: 1.5rem !important;

        &:before {
          @apply rounded-full bg-[#F0F1F4] size-10 text-bn-chat-secondary-grey text-center flex items-center justify-center font-medium transition-transform;
          font-family: 'Material Symbols Rounded', sans-serif;
          content: 'play_arrow';
        }
      }
    }
  }

  .vg-icon-volume_up:before {
    content: 'volume_up';
    font-family: 'Material Symbols Rounded';
    font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 48;
  }

  .vg-icon-volume_off:before {
    content: 'volume_off';
    font-family: 'Material Symbols Rounded';
    font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 48;
  }

  .vg-icon-volume_mute:before {
    content: 'volume_mute';
    font-family: 'Material Symbols Rounded';
    font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 48;
  }

  .vg-icon-volume_down:before {
    content: 'volume_down';
    font-family: 'Material Symbols Rounded';
    font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 48;
  }

  .vg-icon-fullscreen:before {
    content: 'fullscreen';
    font-family: 'Material Symbols Rounded';
    font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 48;
  }

  vg-time-display {
    font-family: 'Orkney', 'Helvetica Neue', sans-serif !important;
    line-height: 52px !important;
  }
}
